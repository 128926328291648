import { FC, useState } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { slideData } from '../../../assets/slideData';
import clsx from 'clsx';
import styles from './CarouselPC.module.scss';
import { SlideComponent } from './CarouselComponents/SlideComponent';
import { SliderControl } from './CarouselComponents/SlideControl';

export const CarouselPC: FC = () => {
  const [current, setCurrent] = useState(0);
  const handlePreviousClick = () => {
    const previous = current - 1;
    setCurrent(previous < 0 ? slideData.length - 1 : previous);
  };
  const handleNextClick = () => {
    const next = current + 1;
    setCurrent(next === slideData.length ? 0 : next);
  };
  const handleSlideClick = (index: number) => {
    if (current !== index) {
      setCurrent(index);
    }
  };
  const wrapperTransform = {
    transform: `translateX(-${current * (100 / slideData.length)}%)`,
  };
  return (
    <div className={clsx('hidden md:block', styles.outer)}>
      <div className={clsx('slider', styles.inner)}>
        <ul className="slider__wrapper" style={wrapperTransform}>
          {slideData.map((slide) => {
            return (
              <SlideComponent
                index={slide.index}
                key={slide.index}
                src={slide.src}
                headline={slide.headline}
                current={current}
                handleSlideClick={handleSlideClick}
              />
            );
          })}
        </ul>
        <div className="slider__controls">
          <SliderControl type="previous" handleClick={handlePreviousClick} />
          <SliderControl type="next" handleClick={handleNextClick} />
        </div>
      </div>
    </div>
  );
};
