import { FC, useRef } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '../CarouselPC.module.scss';

export interface SlideProps {
  src: string;
  headline: string;
  index: number;
  current: number;
  handleSlideClick: (index: number) => void;
}

export const SlideComponent: FC<SlideProps> = ({
  src,
  headline,
  index,
  current,
  handleSlideClick,
}) => {
  const slide = useRef<HTMLLIElement>(null);
  const handleMouseMove = (event: React.MouseEvent<HTMLLIElement>) => {
    const el = slide.current;
    if (el) {
      const r = el.getBoundingClientRect();
      el.style.setProperty('--x', (event.clientX - (r.left + Math.floor(r.width / 2))).toString());
      el.style.setProperty('--y', (event.clientY - (r.top + Math.floor(r.height / 2))).toString());
    }
  };
  const handleMouseLeave = () => {
    const el = slide.current;
    if (el) {
      el.style.setProperty('--x', '0');
      el.style.setProperty('--y', '0');
    }
  };
  const imageLoaded = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.style.opacity = '1';
  };
  let classNames = 'slide';
  if (current === index) classNames += ' slide--current';
  else if (current - 1 === index) classNames += ' slide--previous';
  else if (current + 1 === index) classNames += ' slide--next';
  return (
    <li
      ref={slide}
      className={classNames}
      onClick={() => handleSlideClick(index)}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className="slide__image-wrapper ">
        <img className="slide__image" alt={headline} src={src} onLoad={imageLoaded} />
      </div>
      <article className="slide__content  block ">
        <h2 className="slide__headline">{headline}</h2>
      </article>
    </li>
  );
};
