import { FC, useState } from 'react';
import { motion } from 'framer-motion';
import AerialConstructionFull from '../../assets/images/photos/trenching-vert.png';
import FiberSplicingFull from '../../assets/images/photos/fiber-full.jpg';
import EmergencyMaintenanceFull from '../../assets/images/photos/maintance-full.jpg';

export const Articles: FC = () => {
  const [isInViewSecond, setIsInViewSecond] = useState(false);

  return (
    <div>
      <div className="py-12 relative overflow-hidden bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-screen-lg mx-auto">
          <motion.div
            initial={{ x: -1000, scale: 0.5 }}
            animate={{ x: 0, scale: 1 }}
            transition={{ duration: 0.8 }}
          >
            <div className="w-full flex flex-col items-end md:pr-16">
              <h2 className="text-sky-600 font-bold text-2xl max-w-xs text-right mb-12 md:mt-10 pr-16 md:rl-0">
                Whether you need expert consultation
              </h2>
              <div className="h-full mt-auto overflow-hidden relative">
                <img
                  src={AerialConstructionFull}
                  className="h-full w-full object-contain"
                  alt="team"
                />
              </div>
            </div>
          </motion.div>

          <div className="py-20 bg-slate-100 relative before:absolute before:h-full before:w-screen before:bg-sky-950 before:top-0 before:left-0">
            <div className="relative z-20 px-4 md:pl-12 flex flex-col md:block">
              <h2 className="text-orange-100 font-black text-5xl leading-snug mb-10">
                Our company is here <br />
                to help you
              </h2>
              <p className="text-white text-sm">
                Cablegram is a leading provider of communication solutions for residential and
                commercial customers. We offer a range of services, including Aerial construction,
                Emergency and maintenance, Internet installations, Trenching and other services.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-4 relative overflow-hidden bg-white hidden md:block">
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-screen-lg mx-auto">
          <div className="py-20 bg-orange-100 relative before:absolute before:h-full before:w-screen before:bg-orange-100 before:top-0 before:right-0">
            <div className="relative z-20 px-4 md:pl-12 flex flex-col md:block">
              <h2 className="text-sky-950 font-black text-5xl leading-snug mb-10">
                Find your <br />
                solution
              </h2>
              <p className="text-sky-950 text-sm">
                We are a reputable company made up of highly skilled and extensively trained
                professionals that have been in the business more than 4 years. With expertise in
                various areas we are known for our outstanding performance and dedication to
                excellence.
              </p>
            </div>
          </div>
          <motion.div
            initial={false}
            animate={
              isInViewSecond
                ? {
                    scale: 1,
                  }
                : { scale: 0 }
            }
            transition={{ duration: 0.8, delay: 0 }}
            viewport={{ once: true }}
            onViewportEnter={() => setIsInViewSecond(true)}
          >
            <div className="w-full flex flex-col md:pl-16">
              <h2 className="text-sky-600  font-bold text-2xl max-w-xs text-left mb-12  md:mt-10  pl-16 md:pl-0">
                Whether you need professional installation and maintenance
              </h2>
              <div className="h-full mt-auto overflow-hidden relative">
                <img
                  src={FiberSplicingFull}
                  className="h-full w-full object-contain"
                  alt="team-cablegram"
                />
              </div>
            </div>
          </motion.div>
        </div>
      </div>

      <div className="py-12 relative overflow-hidden bg-white block md:hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-screen-lg mx-auto">
          <div className="w-full flex flex-col items-end md:pr-16">
            <h2 className="text-sky-600  font-bold text-2xl max-w-xs text-right mb-12  md:mt-10 pr-16 md:rl-0">
              Whether you need professional installation and maintenance
            </h2>
            <div className="h-full mt-auto overflow-hidden relative">
              <img
                src={FiberSplicingFull}
                className="h-full w-full object-contain"
                alt="team-cablegram"
              />
            </div>
          </div>

          <div className="py-20 bg-slate-100 relative before:absolute before:h-full before:w-screen before:bg-sky-950 before:top-0 before:left-0 ">
            <div className="relative z-20 px-4 md:pl-12 flex flex-col md:block">
              <h2 className="text-orange-100 font-black text-5xl leading-snug mb-10">
                Find your <br />
                solution with us
              </h2>
              <p className="text-white text-sm">
                We are a reputable company made up of highly skilled and extensively trained
                professionals. With expertise in various areas we are known for our outstanding
                performance and dedication to excellence.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-12 relative overflow-hidden bg-white">
        <div className="grid grid-cols-1 md:grid-cols-2 max-w-screen-lg mx-auto">
          <div className="w-full flex flex-col items-end md:pr-16">
            <h2 className="text-sky-600  font-bold text-2xl max-w-xs text-right mb-12 md:mt-10 pr-16 md:rl-0">
              We are here to assist you with your needs.
            </h2>
            <div className="h-full mt-auto overflow-hidden relative">
              <img
                src={EmergencyMaintenanceFull}
                className="h-full w-full object-contain"
                alt="maintance-team"
              />
            </div>
          </div>

          <div className="py-20 bg-slate-100 relative before:absolute before:h-full before:w-screen before:bg-sky-950 before:top-0 before:left-0 ">
            <div className="relative z-20 px-4 md:pl-12 flex flex-col md:block">
              <h2 className="text-orange-100 font-black text-5xl leading-snug mb-10">
                We work to make <br />
                your life better
              </h2>
              <p className="text-white text-sm">
                Our team continually stays updated with the latest industry standards and techniques
                through continuous training. With a strong commitment to quality and safety, we are
                your reliable choice for all your construction and maintenance needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
