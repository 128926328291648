import { useEffect, useState } from 'react';
import styles from './BackgroundCover.module.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

interface BackgroundCoverProps {
  img: string;
  title: string;
  text: string;
}

export const BackgroundCover = ({ img, title, text }: BackgroundCoverProps) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [backgroundPositionY, setBackgroundPositionY] = useState(0);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const backgroundPositionX = scrollPosition;

  return (
    <div>
      <div
        className={clsx('relative heading ', styles.heading)}
        style={{
          backgroundPosition: `${backgroundPositionX}px ${backgroundPositionY}px`,
        }}
      >
        <div
          className={clsx(
            'absolute bg-cover bg-no-repeat left-0  top-0 h-full w-full bg-fixed',
            styles.inner,
          )}
          style={{
            backgroundImage: `url('${img}')`,
          }}
        >
          <div className="flex h-full items-center justify-center">
            <div className="px-6 text-center text-white md:px-12">
              <h1 className="mb-6 text-5xl font-bold">{title}</h1>
              <h3 className="mb-8 text-3xl font-bold">{text}</h3>
              <Link
                to="/contact"
                className="inline-block  border-2 border-neutral-50 px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-neutral-50 transition duration-150 ease-in-out hover:border-neutral-100 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-neutral-100 focus:border-neutral-100 focus:text-neutral-100 focus:outline-none focus:ring-0 active:border-neutral-200 active:text-neutral-200 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10 mt-2"
                data-te-ripple-init
                data-te-ripple-color="light"
              >
                Contact us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
