import { FC } from 'react';
import orangeDots from '../../../assets/images/svg/svgDotsOrange.svg';
import blueDots from '../../../assets/images/svg/svgDotsBlue.svg';
import oneRowDots from '../../../assets/images/svg/rowDots.svg';
import orangeEnvelope from '../../../assets/images/svg/envelope.svg';

export const FormDecorationElement: FC = () => {
  return (
    <>
      <span className="absolute -top-20 -right-20 z-[1] rotate-45 ">
        <img src={orangeEnvelope} alt="" />
      </span>

      <span className="absolute -right-10 top-[90px] z-[-1]">
        <img src={oneRowDots} alt="" />
      </span>
      <span className="absolute -right-10 top-[216px] z-[-1]">
        <img src={oneRowDots} alt="" />
      </span>

      <span className="absolute -left-5 -bottom-5 z-[-1]">
        <img src={blueDots} alt="" />
      </span>
      <span className="absolute -left-7 -bottom-7 z-[-1]">
        <img src={orangeDots} alt="" />
      </span>
    </>
  );
};
