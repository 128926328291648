import { FC } from 'react';
import { ContactForm } from '../components/Form/ContactForm';

export const Contact: FC = () => {
  return (
    <div>
      <ContactForm />
    </div>
  );
};
