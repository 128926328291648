import { FC } from 'react';
import { Link } from 'react-router-dom';
import styles from './BackgroundHome.module.css';
import clsx from 'clsx';
import { motion } from 'framer-motion';

export const BackgroundHome: FC = () => {
  return (
    <div className={clsx('flex bg-white h-96 md:h-screen ')}>
      <div className="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2">
        <motion.div
          initial={{ x: -1000, scale: 0.5 }}
          animate={{ x: 0, scale: 1 }}
          transition={{ duration: 0.8 }}
        >
          <h2 className="text-3xl font-semibold text-gray-800 md:text-4xl">
            Get your best <span className="text-sky-600">service</span>
          </h2>
          <p className="mt-2 text-sm text-gray-500 md:text-base">
            Reach out to us and benefit from our outstanding services. We are ready to serve you
            anytime, anywhere, and anyhow!
          </p>
          <div className="flex justify-center lg:justify-start mt-6">
            <Link
              className="px-4 py-3 bg-sky-950 text-gray-200 text-md font-semibold rounded hover:bg-gray-800"
              to="/contact"
            >
              Contact us
            </Link>
            <Link
              className="mx-4 px-4 py-3 bg-orange-300 text-sky-950 text-md  font-semibold rounded hover:bg-gray-400"
              to="/services"
            >
              Our services
            </Link>
          </div>
        </motion.div>
      </div>
      <motion.div
        initial={{ x: 1000, scale: 0.5 }}
        animate={{ x: 0, scale: 1 }}
        transition={{ duration: 0.8 }}
        className={clsx('hidden lg:block lg:w-1/2', styles.inner)}
      >
        <div className={clsx('h-full object-cover image', styles.image)}>
          <div className="h-full bg-black opacity-25"></div>
        </div>
      </motion.div>
    </div>
  );
};
