import { FC, useState, useEffect } from 'react';
import phone from '../../assets/images/svg/phone.svg';
import styles from './Header.module.scss';
import clsx from 'clsx';
import xicon from '../../assets/images/svg/xicon.svg';
import { Link, useLocation } from 'react-router-dom';
import { headerData } from '../../assets/headerData';
import { serviceData } from '../../assets/serviceData';
import { motion } from 'framer-motion';
import logoImage from '../../assets/images/logo-picture.jpg';
import logoWords from '../../assets/images/logo-words.jpg';

export const Header: FC = () => {
  const { pathname } = useLocation();
  const [showServiceMenu, setShowServiceMenu] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [classHeader, setClassHeader] = useState('');

  const phoneNumberText = '+1-425-589-9676';

  const variants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.1,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
    closed: {
      opacity: 0.5,
      x: '100%',
      transition: {
        type: 'spring',
        stiffness: 60,
        restDelta: 10,
      },
    },
  };

  const listItemVariants = {
    closed: {
      x: -30,
      opacity: 0,
    },

    open: {
      x: 0,
      opacity: 0.8,
    },
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY;

    if (scrollTop > 400) {
      setClassHeader('fixed z-30');
    } else {
      setClassHeader('');
    }
  };

  return (
    <>
      <div
        className={`bg-white shadow-lg h-24 md:h-28 ${classHeader} w-full  md:flex items-center justify-between  px-8 md:px-12`}
      >
        <div className="flex justify-between items-center py-2 ">
          <div className="text-2xl font-bold text-gray-950 md:text-3xl">
            <Link to="/" className="flex items-center">
              <img
                src={logoImage}
                alt="LOGO"
                width={80}
                className="transition-transform duration-1000 ease-in-out active:-rotate-180"
              />
              <img src={logoWords} alt="LOGO" width={120} />
            </Link>
          </div>
          <div className="md:hidden" onClick={() => setIsOpenMenu(!isOpenMenu)}>
            <button
              type="button"
              className="block text-gray-950 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                <path
                  className="hidden"
                  d="M16.24 14.83a1 1 0 0 1-1.41 1.41L12 13.41l-2.83 2.83a1 1 0 0 1-1.41-1.41L10.59 12 7.76 9.17a1 1 0 0 1 1.41-1.41L12 10.59l2.83-2.83a1 1 0 0 1 1.41 1.41L13.41 12l2.83 2.83z"
                />
                <path d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex-col md:flex-row hidden  md:block -mx-2">
          {headerData.map((nav) => (
            <Link
              key={nav.title}
              to={nav.path}
              onMouseEnter={() =>
                nav.title === 'Services' ? setShowServiceMenu(true) : setShowServiceMenu(false)
              }
              className={clsx(
                'text-gray-950 rounded hover:bg-gray-600 hover:text-gray-100 hover:font-medium py-3 px-3 md:mx-2 relative',
                pathname === nav.path && 'text-white bg-gray-900 ',
              )}
            >
              {nav.title}
            </Link>
          ))}

          <span className={clsx(styles.phone, 'cursor-auto hidden lg:inline-block')}>
            <img src={phone} alt="phone" width={20} className={styles.icon} />
            <span className="text-grey-700">
              {' '}
              {phoneNumberText.split('').map((letter, index) => (
                <motion.span
                  key={index}
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 0.5 }}
                  transition={{ duration: 5, repeat: Infinity, delay: index * 0.1 }}
                >
                  {letter}
                </motion.span>
              ))}
            </span>
          </span>
          <a
            href="tel:+1-425-589-9676"
            className={clsx(styles.phone, 'cursor-auto hidden md:inline-block lg:hidden')}
          >
            <img src={phone} alt="phone" width={20} className={styles.icon} />
          </a>

          <div
            className={clsx(
              ' bg-white absolute top-20 right-40 w-72 z-40',

              !showServiceMenu ? 'hidden' : 'flex flex-col',
            )}
            onMouseLeave={() => setShowServiceMenu(false)}
          >
            {serviceData.map((nav) => (
              <Link
                onClick={() => setShowServiceMenu(false)}
                key={nav.title}
                to={nav.path}
                className={clsx(
                  'text-gray-950  rounded  hover:text-gray-500 hover:font-medium py-2 px-2 md:mx-2',
                )}
              >
                {nav.title}
              </Link>
            ))}
          </div>
        </div>

        {/* // */}

        <motion.nav
          animate={isOpenMenu ? 'open' : 'closed'}
          initial="closed"
          variants={variants}
          className={clsx('fixed   bg-white top-0 left-0 right-0 bottom-0 z-40')}
        >
          {isOpenMenu && (
            <div className={clsx(' flex  px-auto md:flex-none ')}>
              <div className={clsx(styles.inner)}>
                <img
                  onClick={() => setIsOpenMenu(!isOpenMenu)}
                  src={xicon}
                  width={30}
                  alt=""
                  className=" mb-5 cursor-pointer"
                />

                {headerData.map((nav, index) => (
                  <motion.div
                    variants={listItemVariants}
                    transition={{ delay: index / 4 }}
                    key={nav.title}
                    className="text-gray-950  rounded  hover:text-gray-500 hover:font-medium py-2 px-2 md:mx-2"
                    onClick={() => setIsOpenMenu(!isOpenMenu)}
                  >
                    <Link to={nav.path}>{nav.title}</Link>
                  </motion.div>
                ))}

                <a href="tel:+1-425-589-9676" className="mt-5">
                  <img src={phone} alt="" width={20} />
                </a>
              </div>
            </div>
          )}
        </motion.nav>
      </div>
    </>
  );
};
