export const TermsAndConditions = () => {
  return (
    <div>
      <p>
        These Terms and Conditions govern your access and use of Cablegram LLC (hereinafter “we”,
        “us”, or “our”) , owned and operated by Cablegram LLC. Please read these Terms carefully
        before using our Website or App. By accessing or using our Website or App, you agree to be
        bound by these Terms and our Privacy Policy. If you do not agree with these Terms or our
        Privacy Policy, you should stop using our Website or App immediately.
      </p>
      <br />
      <h4>Acceptance of Terms</h4>

      <p>
        By accessing or using our Website or App, you acknowledge that you have read, understood,
        and agreed to be bound by these Terms and our Privacy Policy. If you are accessing or using
        our Website or App on behalf of a business, organization, or entity, you represent and
        warrant that you have the authority to bind such business, organization, or entity to these
        Terms and our Privacy Policy, and that you agree to these Terms and our Privacy Policy on
        behalf of such business, organization, or entity.
      </p>
      <br />

      <h4>License and Intellectual Property</h4>

      <p>
        We grant you a limited, non-exclusive, revocable, and non-transferable license to access and
        use our Website or App for your personal, non-commercial, and lawful purposes only, in
        accordance with these Terms and our Privacy Policy. This license does not include any right
        to copy, reproduce, distribute, modify, create derivative works of, publicly display,
        publicly perform, or otherwise exploit any part of our Website or App or its content, except
        as expressly permitted by us or the applicable licensors. All rights, title, and interest in
        and to our Website or App and its content, including trademarks, logos, images, text,
        software, etc., are and will remain the exclusive property of us or our licensors. Nothing
        in these Terms or our Privacy Policy gives you any right to use any of our or our licensors’
        trademarks, logos, images, text, software, etc., without our prior written consent.
      </p>
      <br />
      <h4>User Content and Conduct</h4>

      <p>
        Our Website or App may allow you to post, upload, or share any content or information on our
        Website or App or through our social media platforms. By posting, uploading, or sharing any
        User Content, you represent and warrant that you own or have the necessary rights and
        permissions to do so, and that your User Content does not violate any of our or our
        licensors’ intellectual property rights, or any applicable laws or regulations. You also
        grant us a non-exclusive, royalty-free, worldwide, perpetual, irrevocable, and
        sub-licensable license to use, reproduce, distribute, modify, create derivative works of,
        publicly display, publicly perform, and otherwise exploit your User Content for any purpose,
        without any compensation or notice to you. You are solely responsible for your User Content
        and your conduct on our Website or App or through our social media platforms. You agree not
        to post, upload, or share any User Content or engage in any conduct that:
      </p>
      <br />
      <ul className="list-disc">
        <li>
          Is unlawful, harmful, threatening, abusive, harassing, defamatory, libelous, slanderous,
          vulgar, obscene, pornographic, hateful, discriminatory, or otherwise objectionable;
        </li>
        <li>
          Infringes, misappropriates, or violates any of our or our licensors’ or any third party’s
          intellectual property rights, privacy rights, publicity rights, or other rights;
        </li>
        <li>
          Impersonates, misrepresents, or falsely states or implies any affiliation with us or any
          other person or entity;
        </li>
        <li>
          Contains or transmits any viruses, malware, spyware, or other harmful or malicious code or
          content that may damage, interfere with, or disrupt the operation or security of our
          Website or App or any other website or app;
        </li>
        <li>
          Contains or transmits any viruses, malware, spyware, or other harmful or malicious code or
          content that may damage, interfere with, or disrupt the operation or security of our
          Website or App or any other website or app;
        </li>
        <li>
          Sends, posts, or displays any unsolicited or unauthorized advertising, promotional
          materials, junk mail, spam, chain letters, pyramid schemes, or any other form of
          solicitation;
        </li>
      </ul>
    </div>
  );
};
