import { FC } from 'react';
import { ServicesCards } from '../components/Services/ServicesCards';
import { ContactForm } from '../components/Form/ContactForm';
import { BackgroundCover } from '../components/BackgroundCover/BackgroundCover';
import { CarouselComponent } from '../components/Carousel/CarouselComponent';
import ServicesImg from '../assets/images/photos/services.jpg';

export const Services: FC = () => {
  return (
    <>
      <BackgroundCover
        img={ServicesImg}
        title="Our services"
        text="Here you can find a range of services for you!"
      />

      <ServicesCards />
      <CarouselComponent />
      <ContactForm />
    </>
  );
};
