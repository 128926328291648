import { FC, useState } from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as Customer } from '../../assets/images/svg/customer.svg';
import { ReactComponent as Quality } from '../../assets/images/svg/quality.svg';
import { ReactComponent as Time } from '../../assets/images/svg/time.svg';
import { ReactComponent as Money } from '../../assets/images/svg/money.svg';
import { cardsDesc } from '../../assets/cardsDescText';

interface DescriptionCardsProps {
  text: string;
  image: string;
}

export const DescriptionCards: FC<DescriptionCardsProps> = ({ text, image }) => {
  const [isInView, setIsInView] = useState(false);
  return (
    <div>
      <section className="relative md:pt-16 md:mx-20 bg-blueGray-50 text-white">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  {/* //            */}
                  <div className="relative flex flex-col mt-8 md:mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-2 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <Quality className="hover:rotate-45 hover:scale-110 transition-all ease-out" />
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">{cardsDesc[0].title}</h6>
                      <p className="mb-4 text-blueGray-500">{cardsDesc[0].text}</p>
                    </div>
                  </div>

                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-2 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <Time className="hover:rotate-45 hover:scale-110 transition-all ease-out" />
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">{cardsDesc[1].title}</h6>
                      <p className="mb-4 text-blueGray-500">{cardsDesc[1].text}</p>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative flex flex-col min-w-0 md:mt-4">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-2 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <Customer className="hover:rotate-45 hover:scale-110 transition-all ease-out" />
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">{cardsDesc[2].title}</h6>
                      <p className="mb-4 text-blueGray-500">{cardsDesc[2].text}</p>
                    </div>
                  </div>
                  <div className="relative flex flex-col min-w-0">
                    <div className="px-4 py-5 flex-auto">
                      <div className="text-blueGray-500 p-2 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-white">
                        <Money className="hover:rotate-45 hover:scale-110 transition-all ease-out" />
                      </div>
                      <h6 className="text-xl mb-1 font-semibold">{cardsDesc[3].title}</h6>
                      <p className="mb-4 text-blueGray-500">{cardsDesc[3].text}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="10/12 lg:w-6/12 px-0 lg:px-4 mr-auto lg:mr-0 ml-auto -mt-78">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg text-orange-300">
                <img alt="CABLEGRAM" src={image} className="w-full align-middle rounded-t-lg" />
                <blockquote className="relative p-8 mb-4">
                  <motion.div
                    initial={false}
                    animate={isInView ? { opacity: 1 } : { opacity: 0 }}
                    transition={{ duration: 2, delay: 0 }}
                    viewport={{ once: true }}
                    onViewportEnter={() => setIsInView(true)}
                  >
                    <p className="text-md font-light mt-10 md:mt-20 lg:mt-10 text-gray-600">
                      {text}
                    </p>
                  </motion.div>
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full rotate-180 -top-0 z-[1]"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-orange-300 fill-current"
                    ></polygon>
                  </svg>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
