import React, { FC } from 'react';

interface TextArticleProps {
  title: string;
  children: React.ReactNode;
}

export const TextArticle: FC<TextArticleProps> = ({ title, children }) => {
  return (
    <div className="text-base leading-7 text-gray-700 ">
      <h1 className="mt-2 text-3xl font-bold tracking-tight text-sky-600 sm:text-4xl">{title}</h1>
      <p className="mt-6">{children}</p>
    </div>
  );
};
