export const cardsDesc = [
  {
    title: 'Quality',
    text: 'We use the latest technology, equipment, and materials to ensure the best results for your communication needs.',
  },
  {
    title: 'Quickness',
    text: 'We handle your requests rapidly, accomplish the tasks successfully, and ensure the quality of our work.',
  },
  {
    title: 'Convenience',
    text: 'We work around your schedule, provide flexible payment options, and offer 24/7 support and feedback.',
  },
  {
    title: 'Worthwhile',
    text: 'We give you the best quality, speed, and convenience at a reasonable price, and we guarantee your satisfaction.',
  },
];
