import { FC } from 'react';
import { Link } from 'react-router-dom';
import { serviceData } from '../../assets/serviceData';
import { headerData } from '../../assets/headerData';
import logoImage from '../../assets/images/logo-picture.jpg';
import logoWords from '../../assets/images/logo-words.jpg';

export const Footer: FC = () => {
  return (
    <footer className="bg-white">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="lg:flex lg:justify-between">
          <div className="mb-6 lg:mb-0">
            <Link to="/" className="flex items-center">
              <img src={logoImage} alt="LOGO" width={40} />
              <img src={logoWords} alt="LOGO" width={60} />
            </Link>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Navigation</h2>
              <ul className="text-gray-500  font-medium">
                {headerData.map((nav) => (
                  <li className="mb-2" key={nav.title}>
                    <Link className="hover:underline" to={nav.path}>
                      {nav.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Services</h2>
              <ul className="text-gray-500  font-medium">
                {serviceData.map((service) => (
                  <li className="mb-2" key={service.title}>
                    <Link className="hover:underline" to={service.path}>
                      {service.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">Legal</h2>
              <ul className="text-gray-500  font-medium">
                <li className="mb-2">
                  <Link to="/privacy-policy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions" className="hover:underline">
                    Terms &amp; Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center ">
            © 2023{' '}
            <Link to="/" className="hover:underline">
              CABLEGRAM LLC
            </Link>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            <a
              href="mailto:cablegramllc@gmail.com?subject=Mail from Cablegram LLC Website"
              className="text-sm text-gray-500 sm:text-center hover:underline"
            >
              cablegramllc@gmail.com
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
