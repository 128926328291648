export const PrivacyPolicyData = () => {
  return (
    <div>
      <p>
        This privacy policy explains how Cablegram LLC (hereinafter “we”, “us”, or “our”) collects,
        uses, shares, and protects your personal information when you use our service. This privacy
        policy also informs you of your rights and choices regarding your personal information.
        Please read this privacy policy carefully before using our service.
      </p>
      <br />

      <h4>Information Collection and Use</h4>

      <p>We collect and use your personal information for the following purposes:</p>

      <ul className="list-disc">
        <li>
          To provide you with our internet installation service, which includes installing,
          maintaining, repairing, or upgrading your internet equipment and network, as well as
          providing you with technical support and customer service.
        </li>
        <li>
          To communicate with you about your service, such as sending you confirmation emails,
          invoices, reminders, notifications, surveys, feedback requests, etc.
        </li>

        <li>
          To improve our service, such as by analyzing your usage, preferences, feedback, or
          satisfaction, and by conducting research and development.
        </li>
        <li>
          To market our service, such as by sending you promotional emails, newsletters, offers, or
          advertisements, or by displaying personalized ads on our website or other platforms.
        </li>
        <li>
          To comply with our legal obligations, such as by responding to requests from authorities,
          courts, or regulators, or by enforcing our terms and conditions, policies, or agreements.
        </li>
      </ul>
      <br />
      <p>We collect your personal information from the following sources:</p>

      <ol className="list-disc">
        <li>
          From you directly, such as when you fill out a form, make a payment, contact us, or
          provide feedback
        </li>
        <li>
          From your device or browser, such as your IP address, device type, operating system,
          browser type, language, location, etc.
        </li>
        <li>
          From cookies or other technologies, such as web beacons, pixels, etc., that we or our
          partners use to collect or store information about your online activities, preferences, or
          behavior.
        </li>
      </ol>
      <br />
      <p>We rely on the following legal bases for processing your personal information:</p>

      <ul className="list-disc">
        <li>
          Your consent, which you can give or withdraw at any time by contacting us, opting out of
          marketing communications, changing your account settings, etc.
        </li>
        <li>
          The performance of a contract, which is necessary to provide you with our service or to
          fulfill your requests.
        </li>
        <li>
          Our legitimate interest, which is to improve our service, market our service, protect our
          service, or comply with our legal obligations, as long as it does not override your rights
          or interests.
        </li>
      </ul>
      <br />
      <h4>Information Sharing and Disclosure</h4>

      <p>
        We do not sell your personal information to anyone. However, we may share your personal
        information with the following third parties for the following purposes:
      </p>

      <ul className="list-disc">
        <li>
          With our subcontractors, partners, or affiliates, who help us provide, improve, or market
          our service, such as installation technicians, network providers, web developers, data
          analysts, email service providers, etc. We only share the information that is necessary
          for them to perform their tasks, and we require them to respect your privacy rights and
          comply with this privacy policy and the applicable laws.
        </li>

        <li>
          With our advertisers or advertising networks, who display personalized ads on our website
          or other platforms, based on your online activities, preferences, or behavior. These third
          parties may use cookies or other technologies to collect or store information about you.
          You can opt out of personalized ads by contacting us, changing your browser settings,
          using a cookie consent tool, etc.
        </li>
        <li>
          With our payment processor or bank, who process your payments for our service, and who may
          collect or store your payment information, such as your credit card number, expiration
          date, security code, etc. We do not have access to or control over your payment
          information, and we are not responsible for their privacy practices. You should review
          their privacy policy before making a payment.
        </li>
        <li>
          With authorities, courts, or regulators, who request or require us to disclose your
          personal information for legal reasons, such as to comply with a subpoena, warrant, order,
          law, regulation, or investigation, or to protect our rights, property, or safety, or the
          rights, property, or safety of others.
        </li>
      </ul>
      <br />
      <h4>Information Retention and Security</h4>

      <p>
        We retain your personal information for as long as it is necessary to provide you with our
        service, fulfill our legal obligations, or satisfy our legitimate interests. When your
        personal information is no longer needed, we delete or dispose of it securely and in
        accordance with the applicable laws.
      </p>

      <p>
        We take reasonable measures to protect your personal information from unauthorized access,
        use, modification, or disclosure, such as encryption, firewalls, passwords, etc. However, we
        cannot guarantee that your personal information is completely safe or immune to any security
        breaches, errors, or incidents. You are responsible for keeping your account information and
        password confidential and for notifying us immediately of any suspected or actual
        unauthorized access or use of your account or personal information.
      </p>
      <br />
      <h4>Information Rights and Choices</h4>
      <p>You have the following rights and choices regarding your personal information:</p>

      <ol className="list-disc">
        <li>
          The right to access your personal information, which means you can request a copy of the
          personal information we have about you, and verify its accuracy and completeness.
        </li>
        <li>
          The right to correct your personal information, which means you can request us to update,
          modify, or complete any inaccurate or incomplete personal information we have about you.
        </li>
        <li>
          The right to delete your personal information, which means you can request us to erase or
          remove any personal information we have about you, unless we have a valid reason to keep
          it, such as to provide you with our service, comply with our legal obligations, or protect
          our legitimate interests.
        </li>
        <li>
          The right to restrict the processing of your personal information, which means you can
          request us to limit or suspend the use of your personal information, if you have a valid
          reason to do so, such as to contest its accuracy, legality, or necessity, or to protect
          your rights or interests.
        </li>
        <li>
          The right to withdraw your consent, which means you can withdraw your consent for us to
          use your personal information for certain purposes, such as marketing, at any time,
          without affecting the lawfulness of the processing before the withdrawal.
        </li>

        <li>
          The right to object to certain uses of your personal information, which means you can
          request us to stop using your personal information for certain purposes, such as
          marketing, if you have a valid reason to do so, such as if it affects your rights or
          interests, or if it is based on our legitimate interest.
        </li>
        <li>
          The right to data portability, which means you can request us to transfer your personal
          information to another service provider, in a structured, commonly used, and
          machine-readable format, if it is technically feasible, and if the processing is based on
          your consent or contract.
        </li>
        <li>
          The right to lodge a complaint with a supervisory authority, which means you can contact
          the relevant data protection authority in your jurisdiction, if you are not satisfied with
          how we handle your personal information, or if you believe that we have violated your
          rights or the applicable laws.
        </li>
      </ol>
      <br />
      <p>
        To exercise any of your rights and choices, you can contact us, or use the options available
        on our website or app. We will respond to your request within a reasonable time and in
        accordance with the applicable laws. We may ask you to verify your identity or provide
        additional information before we can fulfill your request. We may also charge a reasonable
        fee or refuse your request if it is excessive, repetitive, or unfounded.
      </p>
    </div>
  );
};
