import { FC, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { motion, useScroll } from 'framer-motion';
import { FormDecorationElement } from './components/FormDecorationElement';
import { ReactComponent as Email } from '../../assets/images/svg/email.svg';
import { ReactComponent as Phone } from '../../assets/images/svg/phone.svg';
import { ReactComponent as USAMap } from '../../assets/images/svg/usaTestMap.svg';
import clsx from 'clsx';
import styles from './ContactFormWrapper.module.scss';


interface contactFormProps {
  children: ReactNode;
}

export const ContactFormWrapper: FC<contactFormProps> = ({ children }) => {
  const { pathname } = useLocation();
  const [isInView, setIsInView] = useState(false);
  const { scrollYProgress } = useScroll();

  return (
    <div className={clsx(!pathname.includes('contact') && 'p-5')}>
      <section className="bg-white md:py-20 lg:py-[120px] overflow-hidden relative z-10 p-5 flex flex-wrap justify-center">
        {pathname.includes('contact') && (
          <div className="absolute top-0  bg-slate-100 md:h-full w-full  hidden md:block ">
            <USAMap className="absolute top-0 -left-60 bg-slate-100 h-full w-full p-32 opacity-20 " />
            <motion.div
              animate={{
                scale: [0.7, 0.8, 1, 1.2, 1],
              }}
            >
              <div
                className={clsx(
                  'text-6xl text-sky-600 drop-shadow-md shadow-black text-center py-10 ',
                  styles.marker,
                )}
              >
                States we are currently in
              </div>
            </motion.div>
            <div className="absolute top-10  left-10 sm:top-16 sm:left-16 md:top-30 md:left-30 lg:top-36 lg:left-36 xl:top-64 xl:left-64  text-orange-300 h-full w-full mt-5 pt-5 z-3 text-xl sm:text-2xl md:text-3xl ">
              <motion.div
                initial={{ x: -1000, scale: 0.5 }}
                animate={{ x: 0, scale: 1 }}
                transition={{ duration: 0.8 }}
                className="flex justify-items-center items-center  mb-5  "
              >
                <Phone className="mr-3 drop-shadow-md shadow-black text-orange-300 w-6" />
                <span className={clsx('  text-black drop-shadow-md shadow-black', styles.pen)}>
                  +1-425-589-9676
                </span>
              </motion.div>

              <motion.div
                initial={{ x: -1000, scale: 0.5 }}
                animate={{ x: 0, scale: 1 }}
                transition={{ duration: 1.2 }}
                className="flex justify-items-center items-center  mb-5  "
              >
                <a
                  href="mailto:cablegramllc@gmail.com?subject=Mail from Cablegram LLC Website"
                  className="flex justify-items-center items-center hover:opacity-50 transition-all duration-700"
                >
                  <Email className="mr-3  drop-shadow-md shadow-black text-orange-300 w-6" />
                  <div className={clsx('  text-black drop-shadow-md shadow-black', styles.pen)}>
                    cablegramllc@gmail.com
                  </div>
                </a>
              </motion.div>
            </div>
          </div>
        )}

        <div className="container  ">
          <div className="flex flex-wrap lg:justify-between  ">
            <div className="w-full lg:w-1/2 xl:w-6/12 px-4">
              <div className="max-w-[570px] mb-12 lg:mb-0">
                <span className="block mb-4 text-base text-orange-300 font-semibold">
                  Contact Us
                </span>
                <motion.div
                  className={clsx(pathname.includes('contact') && 'block md:hidden')}
                  initial={false}
                  animate={
                    isInView
                      ? {
                        rotate: 0,
                      }
                      : { rotate: 15 }
                  }
                  transition={{ duration: 0.8, delay: 0 }}
                  viewport={{ once: true }}
                  onViewportEnter={() => setIsInView(true)}
                >
                  <h2
                    className=" text-sky-600 mt-10 md:mt-0  mb-6   uppercase  font-bold text-[32px]  sm:text-[40px] lg:text-[36px]  xl:text-[40px] "
                  >
                    GET IN TOUCH WITH US
                  </h2>
                </motion.div>
                <p className="text-base text-body-color leading-relaxed mb-9">
                  We are always available, accessible, and adaptable. Get in touch with us. We will
                  contact you as soon as possible!
                </p>

                <div className=" text-orange-300  pt-5 z-3 text-xl sm:text-2xl md:text-3xl block md:hidden">
                  <a
                    href="tel:+1-425-589-9676"
                    className="flex justify-items-center items-center   cursor-pointer "
                  >
                    <Phone className="mr-3 drop-shadow-md shadow-black text-orange-300 w-10 md:w-20" />
                    <span className=" text-black drop-shadow-md shadow-black">+1-425-589-9676</span>
                  </a>
                  <motion.div
                    initial={{ x: -1000, scale: 0.5 }}
                    animate={isInView ? { x: 0, scale: 1 } : { x: -1000, scale: 0.5 }}
                    transition={{ duration: 0.8, delay: 0 }}
                    viewport={{ once: true }}
                    onViewportEnter={() => setIsInView(true)}
                  >
                    <motion.path
                      d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                      style={{ pathLength: scrollYProgress }}
                      className={clsx('bg-sky-600 z-30 block mb-5  mt-2', styles.line)}
                    />
                  </motion.div>
                  <a
                    href="mailto:cablegramllc@gmail.com?subject=Mail from Cablegram LLC Website"
                    className="flex justify-items-center items-center cursor-pointer "
                  >
                    <Email
                      width={60}
                      className="mr-3  drop-shadow-md shadow-black text-orange-300 w-10 md:w-20"
                    />
                    <span className="  text-black drop-shadow-md shadow-black">
                      cablegramllc@gmail.com
                    </span>
                  </a>
                  <motion.div
                    initial={{ x: -1000, scale: 0.5 }}
                    animate={{ x: 0, scale: 1 }}
                    transition={{ duration: 0.8 }}
                  >
                    <motion.path
                      d="M 0, 20 a 20, 20 0 1,0 40,0 a 20, 20 0 1,0 -40,0"
                      style={{ pathLength: scrollYProgress }}
                      className={clsx('bg-orange-300 z-30 block mt-2', styles.line)}
                    />
                  </motion.div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 xl:w-5/12 md:p-4">
              <div className="bg-white relative rounded-lg m-2 md:m-0 p-6 md:p-8 sm:p-12 shadow-lg  shadow-gray-400">
                {children}
                <div>{!pathname.includes('contact') && <FormDecorationElement />}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
