import { FC } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CarouselPC } from './CarouselPC/CarouselPC';
import { CarouselMobile } from './CarouselMobile/CarouselMobile';

export const CarouselComponent: FC = () => {
  return (
    <>
      <CarouselPC />
      <CarouselMobile />
    </>
  );
};
