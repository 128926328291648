import { ReactComponent as Worker } from './images/svg/worker.svg';
import { ReactComponent as Pipe } from './images/svg/pipe.svg';
import { ReactComponent as Screwdriver } from './images/svg/screwdriver.svg';
import { ReactComponent as Danger } from './images/svg/danger.svg';
import { ReactComponent as Maintenance } from './images/svg/maintance.svg';
import { ReactComponent as Tools } from './images/svg/tools.svg';
import AerialConstruction from './images/photos/aerial-small.jpg';
import FiberSplicing from './images/photos/fiber-small.jpg';
import EmergencyMaintenance from './images/photos/emergency-small.jpg';
import StormRelatedProjects from './images/photos/storm-small.jpg';
import InternetInstallations from './images/photos/internet-small.jpg';
import Trenching from './images/photos/trenching-vert.png';

import AerialConstructionFull from './images/photos/aerial-const-full.jpeg';
import FiberSplicingFull from './images/photos/fiber-full.jpg';
import EmergencyMaintenanceFull from './images/photos/maintance-full.jpg';
import StormRelatedProjectsFull from './images/photos/storm-full.jpg';
import InternetInstallationsFull from './images/photos/internet-full.jpg';
import TrenchingFull from './images/photos/trenching-full.png';
export const serviceData = [
  {
    title: 'Aerial construction',
    path: '/services/aerial-construction',
    icon: Tools,
    text: 'Contact us today and let us help you as soon as possible!',
    description:
      'Experience the unmatched benefits of our aerial construction services: safety, expertise, efficiency, and customer satisfaction. Our highly skilled team ensures strict adherence to safety protocols, delivering projects with utmost care. With our dedication and continuous training, we complete projects efficiently, saving time and meeting deadlines. Aerial construction minimizes disruption and lowers costs, making it an economical and convenient choice. Hear from our satisfied clients and let us exceed your expectations. Contact us today for unparalleled aerial construction services. We provide aerial building services like:',
    description2:
      'At Cablegram, we know how essential communication is in today’s world. That’s why we offer high-quality services for Aerial construction, such as installing fiber optic cable between utility poles, using advanced techniques and equipment.',
    services: [
      'Setting up poles.',
      'Running aerial fiber.',
      'FTTP/FTTH new builds.',
      'BAU/MDU work.',
    ],
    imageSmall: AerialConstruction,
    imageFull: AerialConstructionFull,
  },
  {
    title: 'Fiber splicing',
    path: '/services/fiber-splicing',
    icon: Pipe,
    text: 'Contact us today and let us help you as soon as possible!',
    description:
      'With our fiber splicing services, you can expect highly skilled technicians who are trained in the latest techniques and equipped with advanced tools. We prioritize speed and accuracy to minimize downtime and maximize productivity. Our focus on quality assurance means that your connections will be reliable and secure, with minimal signal loss. We understand the importance of a stable and efficient network, and we are committed to delivering the best results.',
    description2:
      'At Cablegram, we know how crucial communication is in today’s world. That’s why we offer top-notch services for Fiber splicing, such as joining two fiber optic cables together to create a longer or more efficient cable.',
    services: ['New FTTH Build.', 'MDU/BAU work.', 'Proper testing.', 'Emergency work.'],
    imageSmall: FiberSplicing,
    imageFull: FiberSplicingFull,
  },
  {
    title: 'Emergency and maintenance',
    path: '/services/emergency-and-maintenance',
    icon: Maintenance,
    text: 'Contact us today and let us help you as soon as possible!',
    description:
      'Our team is dedicated to responding quickly and efficiently to assess and repair damages caused by severe weather conditions. We prioritize safety while working to restore your network and offer regular inspections and preventive measures to minimize future storm-related damages. Contact us for immediate assistance with your storm-related projects.',
    description2:
      'At Cablegram, we know how vital communication is in today’s world. That’s why we offer exceptional services for Emergency and maintenance, such as responding to urgent issues, fixing broken cables, restoring cell towers, and installing temporary communication systems.',
    services: [
      'Overnight work.',
      'Quick response.',
      'Multiple teams.',
      'Aerial and splicing assistance.',
    ],
    imageSmall: EmergencyMaintenance,
    imageFull: EmergencyMaintenanceFull,
  },
  {
    title: 'Storm related projects',
    path: '/services/storm-related-projects',
    icon: Danger,
    text: 'Contact us today and let us help you as soon as possible!',
    description:
      'At Cablegram, we know how vital communication is in times of emergency. That’s why we offer specialized services for storm related projects, such as fixing broken cables, and installing temporary communication systems. We also use the latest technology and equipment to ensure the safety and quality of our work. We follow the best practices and standards of the industry and comply with all the regulations and requirements.',
    description2:
      'Whether you need to restore your communication services after a storm, upgrade your network infrastructure, or conduct a field research project, Cablegram is your reliable solution for all your communication needs.',
    services: ['Pole installations.', 'Aerial construction.', 'Fiber splicing.', 'UG pulling.'],
    imageSmall: StormRelatedProjects,
    imageFull: StormRelatedProjectsFull,
  },
  {
    title: 'Internet installations',
    path: '/services/internet-installations',
    icon: Screwdriver,
    text: 'Contact us today and let us help you as soon as possible!',
    description:
      'Our highly skilled technicians have worked with leading cable internet providers such as Xfinity, Ziply, Frontier, and Spectrum. They bring a wealth of experience and expertise in handling installations. You can trust our technicians to deliver a seamless and efficient installation process, ensuring that you have a reliable and high-speed internet connection. We specialize in internet installation in the following areas:',
    description2:
      "At Cablegram, we know how vital the Internet is for your work, education, and entertainment. That's why we offer exceptional services for Internet installations, such as setting up high-speed broadband, connecting multiple devices, securing your network, and providing technical support.",
    services: ['New installations.', 'Business installations.', 'Upgrades.', 'Trouble calls.'],
    imageSmall: InternetInstallations,
    imageFull: InternetInstallationsFull,
  },
  {
    title: 'Trenching',
    path: '/services/trenching',
    icon: Worker,
    text: 'Contact us today and let us help you as soon as possible!',
    description:
      'Our technicians are skilled in trenching services for a variety of purposes, not just limited to internet installations. Whether you need trenching for utilities such as water, electrical, or sewer lines, our technicians have the knowledge and experience to get the job done. They understand the importance of proper trenching techniques to ensure the safety and functionality of these essential systems. By following industry regulations and guidelines, our technicians will ensure that the trenching process is completed efficiently and accurately. We perform trenching services in the following fields:',
    description2:
      "At Cablegram, we know how crucial Trenching is for laying underground cables and pipes. That's why we offer exceptional services for Trenching, such as digging precise trenches, installing durable conduits, filling and compacting soil, and restoring the surface.",
    services: [
      'Straight: A trench with sides that are parallel and at right angles to the base.',
      'Sloped: A trench with angled sides to prevent cave-ins, commonly used on new construction sites or for installing large pipes or culverts.',
      'Benched: A trench with sides that have been cut away to form steps.',
      'Bell-Bottom Pier Hole: A trench with a top that’s narrower than its bottom, creating a bell shape, often used for footing installations that support a structure’s foundation.',
    ],
    imageSmall: Trenching,
    imageFull: TrenchingFull,
  },
];
