import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TextArticle } from '../components/TextArticle/TextArticle';
import { PrivacyPolicyData } from '../components/DocumentsText/privacyPolicyData';
import { TermsAndConditions } from '../components/DocumentsText/termsAndConditions';

import { motion, useScroll, useSpring } from 'framer-motion';

export const DocumentPage: FC = () => {
  const [classHeader, setClassHeader] = useState('');
  const { pathname } = useLocation();
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY;

    if (scrollTop > 300) {
      setClassHeader('margin');
    } else {
      setClassHeader('');
    }
  };

  return (
    <>
      <motion.path className={`progress-bar ${classHeader}`} style={{ scaleX }} />
      <div className="bg-white p-10">
        {' '}
        <TextArticle
          title={
            pathname === '/terms-and-conditions'
              ? 'Terms and conditons'
              : pathname === '/privacy-policy'
              ? 'Privacy policy'
              : ''
          }
        >
          {pathname === '/terms-and-conditions' ? (
            <TermsAndConditions />
          ) : pathname === '/privacy-policy' ? (
            <PrivacyPolicyData />
          ) : (
            ''
          )}
        </TextArticle>
      </div>
    </>
  );
};
