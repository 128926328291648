import styles from './Services.module.scss';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { serviceData } from '../../assets/serviceData';
import { FC } from 'react';
import { ReactComponent as Arrow } from '../../assets/images/svg/arrow.svg';
import { motion } from 'framer-motion';

export const ServicesCards: FC = () => {
  return (
    <div id="services" className="section relative pt-20 pb-8 md:pt-16 md:pb-0 bg-white">
      <div className="container xl:max-w-6xl mx-auto px-4">
        <div className="text-center mx-auto mb-12 lg:px-20">
          <motion.div
            animate={{
              scale: [0.7, 0.8, 1, 1.2, 1],
            }}
          >
            <h2 className="text-2xl leading-normal mb-2 font-bold text-sky-950">What We Do</h2>
          </motion.div>

          <p className="text-gray-500 leading-relaxed font-light text-xl mx-auto pb-2">
            Welcome to Cablegram, the leading provider of communication solutions. <br /> Don't
            settle for less than the best.
          </p>
        </div>

        <div className="flex flex-wrap flex-row -mx-4 text-center">
          {serviceData.map((el, index) => (
            <div
              key={el.title}
              className="flex-shrink px-4 max-w-full w-full sm:w-1/2 lg:w-1/3 lg:px-6 wow fadeInUp cursor-pointer"
              data-wow-duration="1s"
            >
              <Link to={el.path}>
                <div
                  className={clsx(
                    'py-8 px-12 mb-12 bg-stone-800 border-b border-gray-100 transform transition duration-300 ease-in-out hover:-translate-y-2',
                    styles.card,
                    index === 0
                      ? styles.one
                      : index === 1
                      ? styles.two
                      : index === 2
                      ? styles.three
                      : index === 3
                      ? styles.four
                      : index === 4
                      ? styles.five
                      : index === 5
                      ? styles.six
                      : styles.one,
                  )}
                >
                  <div className="inline-block text-sky-300 mb-4">{<el.icon width={60} />}</div>
                  <h3 className="text-lg  mb-2 font-semibold text-sky-300">{el.title}</h3>
                  <p className="text-gray-100 mx-auto  ">
                    <span className="text-md   ">To know more</span>
                    <Arrow className={clsx('h-10 text-gray-100 mx-auto ', styles.arrow)} />
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
