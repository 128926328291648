import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import axios from 'axios';
import { ContactFormWrapper } from '../../layout/ContactFormWrapper/ContactFormWrapper';
import { Modal } from './FromComponents/Modal';
import Error from '../../assets/images/svg/error.svg'
import Success from '../../assets/images/svg/success.svg'
import 'react-phone-number-input/style.css';
import './ContactForm.module.scss';


interface contactFormData {
  message?: string | undefined;
  name: string;
  email: string;
  phone: string;
}

export const ContactForm: FC = () => {
  const [phoneValue, setPhoneValue] = useState<any>();
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [messageValue, setMessageValue] = useState('');
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const { pathname } = useLocation();
  const API_URL = process.env.REACT_APP_API_URL;

  const schema = object().shape({
    name: string().required('Name is required'),
    email: string().email().required('Email is required'),
    phone: string()
      .required('Phone number is required')
      .min(10, 'The number is too short')
      .max(20, 'The number is too long'),
    message: string().max(1000, 'The message is too long'),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const sendPostRequest = async (data: contactFormData) => {
    const apiData = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      message: data.message === undefined ? 'no message' : data.message,
    };

    if (API_URL) {
      try {
        await axios.post(API_URL, apiData);
        setShowModalSuccess(true);
        setTimeout(() => {
          setShowModalSuccess(false);
        }, 7000);
      } catch (error) {
        alert('Something went wrong. Please, try again later!');
        setShowModalError(true);
        setTimeout(() => {
          setShowModalError(false);
        }, 7000);
      }
    } else {
      alert('Something went wrong. Please, try again later!');
      setShowModalError(true);
      setTimeout(() => {
        setShowModalError(false);
      }, 7000);
    }
  };

  const sendContactData = (data: contactFormData) => {
    sendPostRequest(data);
    setNameValue('');
    setEmailValue('');
    setPhoneValue('');
    setMessageValue('');
  };

  return (
    <ContactFormWrapper>
      <form onSubmit={handleSubmit(sendContactData)}>
        <div className="mb-3">
          <input
            type="text"
            placeholder="Your Name"
            {...register('name')}
            value={nameValue}
            onChange={(e) => {
              setNameValue(e.target.value);
            }}
            className="w-full rounded py-3 px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary "
          />
        </div>
        {errors.name && <p className="text-red-600 text-sm mb-3">{errors.name.message}</p>}
        <div className="mb-3">
          <input
            type="email"
            placeholder="Your Email"
            {...register('email')}
            value={emailValue}
            onChange={(e) => {
              setEmailValue(e.target.value);
            }}
            className=" w-full rounded  py-3   px-[14px]   text-body-color text-base  border border-[f0f0f0] outline-none focus-visible:shadow-none focus:border-primary"
          />
        </div>
        {errors.email && <p className="text-red-600 text-sm mb-3">{errors.email.message}</p>}
        <div className="mb-3">
          <PhoneInput
            defaultCountry="US"
            control={control}
            rules={{
              validate: isPossiblePhoneNumber,
            }}
            type="text"
            placeholder="Your Phone"
            {...register('phone')}
            className="w-full  rounded py-3  px-[14px] text-body-color text-base border border-[f0f0f0] outline-none focus-visible:shadow-none"
            value={phoneValue}
            onChange={(e) => {
              setPhoneValue(e);
            }}
            error={phoneValue && !isPossiblePhoneNumber(phoneValue) ? 'Invalid phone number' : ''}
          />
        </div>
        {errors.phone && <p className="text-red-600 text-sm mb-3">{errors.phone.message}</p>}

        {pathname.includes('contact') && (
          <div className="mb-6">
            <textarea
              rows={6}
              placeholder="Your Message"
              {...register('message')}
              value={messageValue}
              onChange={(e) => {
                setMessageValue(e.target.value);
              }}
              className="w-full  rounded py-3  px-[14px] text-body-color text-base  border border-[f0f0f0] resize-none  outline-none focus-visible:shadow-none  focus:border-primary"
            ></textarea>
          </div>
        )}
        {errors.message && <p className="text-red-500">{errors.message.message}</p>}
        <div>
          <button
            type="submit"
            className="w-full text-sky-950 bg-primary rounded border border-primary p-3 transition  hover:bg-opacity-90 focus:border-primary"
          >
            Send Message
          </button>
        </div>
        {showModalSuccess && (
          <Modal setShowModalSuccess={setShowModalSuccess} text1='Done! ' text2='We will contact you as soon as possible!' color='green' img={Success} textImg='success' />
        )}
        {showModalError && (
          <Modal setShowModalSuccess={setShowModalSuccess} text1='Ooops! ' text2='..Something went wrong.' color='red' img={Error} textImg='error' />
        )}
      </form>
    </ContactFormWrapper>
  );
};
