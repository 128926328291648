import { FC } from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  Image,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { slideData } from '../../../assets/slideData';
import styles from './CarouselMobile.module.scss';

export const CarouselMobile: FC = () => {
  return (
    <>
      <div className="my-5 relative mx-10 md:mx-36 block md:hidden ">
        <CarouselProvider
          naturalSlideWidth={50}
          naturalSlideHeight={30}
          totalSlides={slideData.length}
        >
          <Slider>
            {slideData.map((slide, index) => (
              <Slide className="text-white" key={index} index={index}>
                <Image alt={slide.headline} src={slide.src} hasMasterSpinner className="relative" />
                <h3 className="text-white absolute inset-0 flex justify-center items-center text-xl md:text-3xl">
                  {slide.headline}
                </h3>
              </Slide>
            ))}
          </Slider>
          <DotGroup className={styles.dotGroup} />
          <ButtonBack className="text-white absolute bottom-1/2 left-2 ">
            <svg className="icon hidden md:block" viewBox="0 0 24 24">
              <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </ButtonBack>
          <ButtonNext className="text-white absolute bottom-1/2 right-2  ">
            <svg className="icon hidden md:block" viewBox="0 0 24 24">
              <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
            </svg>
          </ButtonNext>
        </CarouselProvider>
      </div>
    </>
  );
};
