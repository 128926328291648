import { FC } from 'react';
import CountUp from 'react-countup';

export const NumbersArticle: FC = () => {
  return (
    <>
      <div className="bg-white py-4 md:py-24 sm:py-10 relative">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div>
            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-sky-600 sm:text-4xl">
                Some numbers about us
              </h1>
              <div className="max-w-xl">
                <p className="mt-6">
                  We have been in the business more than 4 years, and we have a team of experienced
                  and qualified technicians who can handle any communication challenge.
                </p>
              </div>
            </div>
            <dl className="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/20 pt-10 sm:grid-cols-4">
              <div>
                <dt className="text-sm font-semibold leading-6 text-gray-600">Founded</dt>
                <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-sky-950">
                  End of{'  '}
                  <CountUp
                    start={0}
                    end={2021}
                    duration={15}
                    separator=""
                  />
                </dd>
              </div>
              <div>
                <dt className="text-sm font-semibold leading-6 text-gray-600">Employees</dt>
                <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-sky-950">
                  <CountUp
                    start={0}
                    end={10}
                    duration={15}
                    separator=" "
                  />
                </dd>
              </div>
              <div>
                <dt className="text-sm font-semibold leading-6 text-gray-600">States</dt>
                <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-sky-950">
                  <CountUp
                    start={0}
                    end={5}
                    duration={15}
                    separator=" "
                  />
                </dd>
              </div>
              <div>
                <dt className="text-sm font-semibold leading-6 text-gray-600">Finished projects</dt>
                <dd className="mt-2 text-3xl font-bold leading-10 tracking-tight text-sky-950">
                  <CountUp
                    start={0}
                    end={1036}
                    duration={15}
                    separator=" "
                  />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
};
