import { FC } from 'react';
import { Articles } from '../components/Articles/Articles';
import { BackgroundCover } from '../components/BackgroundCover/BackgroundCover';
import { ContactForm } from '../components/Form/ContactForm';
import AboutUS from '../assets/images/photos/about-us.jpg';

export const About: FC = () => {
  return (
    <>
      <BackgroundCover
        img={AboutUS}
        title="About us"
        text="Get in touch with us today and get connected with Cablegram"
      />
      <Articles />
      <ContactForm />
    </>
  );
};
