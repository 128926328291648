import './App.scss';
import { useEffect, useLayoutEffect } from 'react';
import { Footer } from './layout/Footer/Footer';
import { Header } from './layout/Header/Header';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Services } from './pages/Services';
import { NotFound } from './pages/NotFound';
import { Contact } from './pages/Contact';
import { ServicePage } from './pages/ServicePage';
import { DocumentPage } from './pages/DocumentPage';

function App() {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/aerial-construction" element={<ServicePage />} />
        <Route path="/services/fiber-splicing" element={<ServicePage />} />
        <Route path="/services/emergency-and-maintenance" element={<ServicePage />} />
        <Route path="/services/storm-related-projects" element={<ServicePage />} />
        <Route path="/services/internet-installations" element={<ServicePage />} />
        <Route path="/services/trenching" element={<ServicePage />} />
        <Route path="/privacy-policy" element={<DocumentPage />} />
        <Route path="/terms-and-conditions" element={<DocumentPage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
