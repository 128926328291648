import { BackgroundCover } from '../components/BackgroundCover/BackgroundCover';
import { DescriptionCards } from '../components/DescriptionCards/DescriptionCards';
import { ContactForm } from '../components/Form/ContactForm';
import { useLocation } from 'react-router-dom';
import { serviceData } from '../assets/serviceData';
import { TextArticle } from '../components/TextArticle/TextArticle';
import { FC } from 'react';

export const ServicePage: FC = () => {
  const { pathname } = useLocation();

  return (
    <div>
      {serviceData.map(
        (el) =>
          el.path === pathname && (
            <div key={el.title}>
              <BackgroundCover img={el.imageFull} title={el.title} text={el.text} />
              <div className="bg-white py-4 md:py-24 sm:py-10 relative">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                  <div>
                    <TextArticle title={el.title}>{el.description}</TextArticle>
                  </div>
                  <ul className="text-base leading-7 text-gray-700 list-disc mt-5 md:ml-10 mb-5 md:mb-0 ">
                    {el.services.map((service, index) => (
                      <li key={index}>{service}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <DescriptionCards text={el.description2} image={el.imageSmall} />
            </div>
          ),
      )}
      <ContactForm />
    </div>
  );
};
