import AerialConstructionFull from './images/photos/aerial-const-full.jpeg';
import FiberSplicingFull from './images/photos/fiber-full.jpg';
import EmergencyMaintenanceFull from './images/photos/maintance-full.jpg';
import StormRelatedProjectsFull from './images/photos/storm-full.jpg';
import InternetInstallationsFull from './images/photos/internet-small.jpg';
import TrenchingFull from './images/photos/trenching-vert-small.png';

export const slideData = [
  {
    index: 0,
    headline: 'Aerial Construction',
    src: AerialConstructionFull,
  },
  {
    index: 1,
    headline: 'Fiber Splicing',
    src: FiberSplicingFull,
  },
  {
    index: 2,
    headline: 'Emergency Maintenance',
    src: EmergencyMaintenanceFull,
  },
  {
    index: 3,
    headline: 'Storm Related Projects',
    src: StormRelatedProjectsFull,
  },
  {
    index: 4,
    headline: 'Internet Installations',
    src: InternetInstallationsFull,
  },
  {
    index: 5,
    headline: 'Trenching',
    src: TrenchingFull,
  },
];
