import { FC } from 'react'


interface ModalProps {
    setShowModalSuccess: (value: React.SetStateAction<boolean>) => void;
    color: string; text1: string; text2: string; img: string; textImg: string;
}

export const Modal: FC<ModalProps> = ({ setShowModalSuccess, color, text1, text2, img, textImg }) => {
    return (
        <div
            className={color === 'green' ? `bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mt-3` : `bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-3`}
            role="alert"
        >
            <strong className="font-bold">{text1}</strong>
            <span className="block sm:inline"> {text2}</span>
            <span
                className="absolute top-0 bottom-0 right-0 px-4 py-3"
                onClick={() => setShowModalSuccess(false)}
            >
                <img src={img} alt={textImg} />
            </span>
        </div>
    )
}
