import { FC, useState } from 'react';
import { motion } from 'framer-motion';
import internet from '../../assets/images/photos/internet-small.jpg';
import aerial from '../../assets/images/photos/aerial-article.jpg';

const services = ['Consultation', 'Installation', 'Repair', 'Maintance'];

export const Article: FC = () => {
  const [isInView, setIsInView] = useState(false);
  return (
    <div className="relative flex min-h-screen flex-col justify-center overflow-hidden  bg-gray-50 py-6 px-2">
      <div className="w-full items-center mx-auto max-w-screen-lg">
        <div className="group grid w-full grid-cols-1 lg:grid-cols-2">
          <div className="  relative  p-6  overflow-hidden ">
            <img src={aerial} className="w-3/5 rounded-xl  " alt="cablegram-team" />
            <img
              src={internet}
              className="w-3/5 absolute bottom-0 right-5  rounded-xl"
              alt="cablegram-maintenance"
            />
          </div>

          <div className="px-2 md:px-0">
            <div className=" md:pl-12 ">
              <h2 className="text-5xl font-medium my-6 md:my-0 md:mb-6">Who we are</h2>
              <h3 className="text-2xl font-medium mb-6">
                Cablegram is a leading provider of communication solutions for residential and
                commercial customers. We offer a range of services, including Aerial construction,
                Internet installations and other.
              </h3>
              <p className="mb-6 text-gray-400">
                We are a reputable company made up of highly skilled and extensively trained
                professionals.
              </p>
              <h3 className="mb-4 font-semibold text-xl text-gray-400">
                We are committed to your satisfaction and we guarantee our work.
              </h3>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 justify-between mb-3">
                {services.map((service, index) => (
                  <motion.div
                    initial={false}
                    animate={
                      isInView
                        ? {
                            opacity: 1,
                            scale: 1,
                          }
                        : { opacity: 0, scale: 0.5 }
                    }
                    transition={{ duration: 1, delay: index / 3 }}
                    viewport={{ once: true }}
                    onViewportEnter={() => setIsInView(true)}
                    className="flex items-center gap-3 "
                    key={service}
                  >
                    <span className="h-10 w-10 rounded-full bg-sky-300 flex items-center justify-center text-white shadow-lg shadow-sky-500/30 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                    </span>
                    <span className="font-semibold">{service}</span>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
