import { FC } from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import '../CarouselPC.module.scss';

export interface SliderControl {
  type: string;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const SliderControl: FC<SliderControl> = ({ type, handleClick }) => {
  return (
    <button className={`btn btn--${type}`} onClick={handleClick}>
      <svg className="icon" viewBox="0 0 24 24">
        <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    </button>
  );
};
