import { FC } from 'react';
import { BackgroundHome } from '../components/BackgroundHome/BackgroundHome';
import { DescriptionCards } from '../components/DescriptionCards/DescriptionCards';
import { Article } from '../components/Article/Article';
import { ContactForm } from '../components/Form/ContactForm';
import { NumbersArticle } from '../components/NumbersArticle/NumbersArticle';
import { CarouselComponent } from '../components/Carousel/CarouselComponent';
import work from '../../src/assets/images/photos/aerial-construction.jpg';

const HomeText =
  "At Cablegram, we know how valuable your communication needs are. That's why we offer professional services for Aerial construction, Fiber splicing, Emergency and maintenance, Internet installations, Trenching and other. We are ready to serve you anytime, anywhere, and anyhow.";
export const Home: FC = () => {
  return (
    <>
      <BackgroundHome />
      <Article />
      <DescriptionCards image={work} text={HomeText} />
      <NumbersArticle />
      <CarouselComponent />
      <ContactForm />
    </>
  );
};
